<template>
  <section>
    <div class="background">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-position="left"
        class="login-container"
      >
        <div class="title_text" style="fontfamily: 'SourceHanSansSC-';text-align: center;">
          养护作业安全卫士管理系统
        </div>
        <el-form-item prop="account">
          <el-input
            type="text"
            class="input"
            v-model="ruleForm.account"
            auto-complete="off"
            :placeholder="accountext"
            @focus="blurSearchFor()" @blur="blurSear"
          ></el-input>
        </el-form-item>
        <el-form-item prop="checkPass">
          <el-input
            type="password"
            class="input"
            v-model="ruleForm.checkPass"
            auto-complete="off"
            :placeholder="pwdtext"
            @focus="blurfous()" @blur="blurpwd"
            @keyup.enter.native="handleSubmit('ruleForm')"
          ></el-input>
        </el-form-item >
        <div class="item_right">
<el-checkbox v-model="automaticLogon" style="width: 185px"
          >记住密码</el-checkbox
        >
        </div>
        
        <el-form-item style="margin-bottom: 0;text-align: center;">
          <div class="bottom_btn">
   <el-button
          style="backgroundColor:#0076F6;border: 1px solid #0076F6;font-weight: 900;"
            type="primary"
            @click.native.prevent="handleSubmit('ruleForm')"
            icon="el-icon-arrow-right"
            circle
          >
           
          </el-button>
          </div>
       
          <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
  </section>
</template>

<script>
import { login } from "../api/loginapi";

import utils from "../util/encrypt";
export default {
  data() {
    return {
      accountext:'账号:',
      pwdtext:'密码:',
      logining: false,
      login: "登录",
      ruleForm: {
        account: "",
        checkPass: "",
      },
      rules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        checkPass: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      checked: true,
      automaticLogon: false,
    };
  },
  methods: {
      blurSearchFor(){
          if(this.accountext=='账号:'){
              this.accountext='';
          }
          // if(this.pwdtext=='密码:'){
          //     this.pwdtext='';
          // }
      },
        //失去焦点时触发
      blurSear(){
          this.accountext='账号:';
          // this.pwdtext='密码:';
      },
      blurfous(){
          if(this.pwdtext=='密码:'){
              this.pwdtext='';
          }
          // if(this.pwdtext=='密码:'){
          //     this.pwdtext='';
          // }
      },
        //失去焦点时触发
      blurpwd(){
          this.pwdtext='密码:';
          // this.pwdtext='密码:';
      },
    go() {},
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.savePass();
          this.logining = true;
          this.login = "登录中";
          var param = new URLSearchParams();
          param.append("username", this.ruleForm.account);
          param.append("password", this.ruleForm.checkPass);
          login(param)
            .then((response) => {
              this.logining = false;
              console.log(response.data);

              if (response.data.code !== 200) {
                console.log(response);
                // this.$message({
                //   message: message,
                //   type: "error",
                // });
                this.login = "登录";
                // this.$cookies.set("token", true);
                // this.$cookies.set("user", JSON.stringify(uname));
                // this.$cookies.set("userRoleId", roleId);
              } else if (response.data.code == 200) {
                console.log(response.data, "数据");

                this.$cookies.set("token", response.data.token);

                this.$cookies.set("user", response.data.name);
                this.$cookies.set("userRoleId", response.data.is_admin);
                console.log(this.$router, "这里");
                this.$router.push("/home");
              }
            })
            .catch((val) => {
              this.$message({
                type: "error",
                message: val,
              });
              this.logining = false;
              this.login = "登录";
            });
        } else {
          this.$message({
            type: "error",
            message: "请输入正确的用户名密码",
          });
        }
      });
    },
    savePass() {
      if (this.automaticLogon) {
        // Encrypt 加密
        let account = utils.encrypt(this.ruleForm.account);
        let pass = utils.encrypt(this.ruleForm.checkPass);
        this.$cookies.set("account", account, { expires: 7 });
        this.$cookies.set("pass", pass, { expires: 7 });
      } else {
        this.$cookies.remove("account");
        this.$cookies.remove("pass");
      }
    },
  },
  mounted: function () {
    if (this.$cookies.get("account") && this.$cookies.get("pass")) {
      this.ruleForm.account = utils.decrypt(this.$cookies.get("account"));
      this.ruleForm.checkPass = utils.decrypt(this.$cookies.get("pass"));
      this.automaticLogon = utils.decrypt(this.$cookies.get("account"))
        ? true
        : false;
    }
  },
};
</script>

<style scoped>
.title_text {
  font-family: "SourceHanSansSC-";
  font-size: 24px;
  color: #ffffff;
  padding: 30px 0;
}
.item_right{
  text-align: right;
}
.el-icon-arrow-right{
  font-weight: 400!important;
}
.bottom_btn{
  margin-top: 13px;
  margin-bottom: 0!important;
}
section {
  width: 100%;
  height: 100%;
  background-color: #161e2f;
}

.el-form-item >>> .el-input__inner {
  -web-kit-appearance: none;
  -moz-appearance: none;
  background-color: rgba(153, 153, 153, 0.2);
  /* outline: 0; */
  color: #ffffff;
}
/* 谷歌 */
.el-form-item >>>.el-input__inner::-webkit-input-placeholder {
  color: #ffffff;
  font-size: 14px;
}

/* 火狐 */
.el-form-item >>>.el-input__inner:-moz-placeholder {
  color: #ffffff;
  font-size: 14px;
}

/*ie*/
 .el-form-item >>>.el-input__inner:-ms-input-placeholder {
    color: #ffffff;
  font-size: 14px;
}

.el-form-item >>> .el-input__inner:focus::-webkit-input-placeholder {
   color: #ffffff;
  font-size: 14px;
}

.el-form-item >>> .el-input__inner:focus::-moz-input-placeholder {
    color: #ffffff;
  font-size: 14px;
}

.el-form-item >>> .el-input__inner:focus::-ms-input-placeholder {
    color: #ffffff;
  font-size: 14px;
}


.background {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../public/img/login.png") center no-repeat;
  background-size: 100% 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

h1 {
  padding: 30px 0;
}

.login-container {
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
  -webkit-border-radius: 5px;
  border-radius: 10px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
  width: 263px;
  padding: 0px 35px 30px 35px;
  background: rgba(0, 0, 0, 0.8);
  border: 0px solid rgba(243, 243, 243, 0.7);
  opacity: 0.95;
}

.el-button {
      width: 50px;
    height: 50px;
    font-size: 25px;
}
</style>