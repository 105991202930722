import service from "../util/link"
import url from "../util/apiurl"


/*登录请求*/
export function login(obj) {
    return new Promise((resolve, reject) => {
        console.log(obj)
        service.request({
            url: url.login,
            method: "post",
            data: obj
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}